import { memo } from 'react';
import { NumericFormat } from 'react-number-format';
import { Input, Label } from 'reactstrap';

function AggregatorBillingKPIs({
  aggregatorMonthlySubscriptionFee,
  aggregatorNumberOfEmployees,
  aggregatorTurnoverBenchmerk,
  aggregatorTurnoverBenchmerkSource,
  aggregatorEmployeeReplacementCost,
  aggregatorEmployeeReplacementCostSource,
  changeValue,
}) {
  return (
    <>
      <div className="mx-3 mt-4 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Monthly Subscription Fee ($):</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="monthlySubscriptionFee"
            name="monthlySubscriptionFee"
            value={aggregatorMonthlySubscriptionFee || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="mx-3 mt-2 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Number of Employees:</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="numberOfEmployees"
            name="numberOfEmployees"
            value={aggregatorNumberOfEmployees || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </div>
      </div>
      <div className="mx-3 mt-2 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Annual Turnover Benchmark:</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="turnoverBenchmerk"
            name="turnoverBenchmerk"
            value={aggregatorTurnoverBenchmerk || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
          />
        </div>
        <Label className="mx-3 bo-w-60 mb-0">Source:</Label>
        <div>
          <Input
            className="bo-input"
            type="text"
            name="turnoverBenchmerkSource"
            id="turnoverBenchmerkSource"
            value={aggregatorTurnoverBenchmerkSource || ''}
            onChange={changeValue}
          />
        </div>
      </div>
      <div className="mx-3 my-2 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Employee Replacement Cost ($):</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="employeeReplacementCost"
            name="employeeReplacementCost"
            value={aggregatorEmployeeReplacementCost || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
          />
        </div>
        <Label className="mx-3 bo-w-60 mb-0">Source:</Label>
        <div>
          <Input
            className="bo-input"
            type="text"
            name="employeeReplacementCostSource"
            id="employeeReplacementCostSource"
            value={aggregatorEmployeeReplacementCostSource || ''}
            onChange={changeValue}
          />
        </div>
      </div>
    </>
  );
}

export default memo(AggregatorBillingKPIs);
